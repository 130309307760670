<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>订单管理</template>
      <template v-slot:secondMenu>退货设置</template>
    </breadcrumb-nav>
    <!--<el-card style="margin: 10px 0px">
      <el-alert style="margin-bottom: 10px;" type="warning" title="注意：设置退货信息前请先选择店铺" :closable="false" show-icon></el-alert>
      <shop-select @shopChange="shopChange"></shop-select>
    </el-card>-->
    <el-card>
      <el-tabs @tab-click="tabClicked" v-model="activeTab">
        <el-tab-pane label="退货地址" name="退货地址">
          <refund-address-pane :is-btn-disabled="isBtnDisabled" :shop-id="shopId" ref="address"></refund-address-pane>
        </el-tab-pane>
        <el-tab-pane label="退货原因" name="退货原因">
          <refund-reason-pane :is-btn-disabled="isBtnDisabled" :shop-id="shopId" ref="reason"></refund-reason-pane>
        </el-tab-pane>
        <el-tab-pane label="退货类型" name="退货类型">
          <refund-option-type-pane :is-btn-disabled="isBtnDisabled" :shop-id="shopId" ref="refund"></refund-option-type-pane>
        </el-tab-pane>
        <el-tab-pane label="退货方式" name="退货方式">
          <refund-option-pane :is-btn-disabled="isBtnDisabled" :shop-id="shopId" ref="dy"></refund-option-pane>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
// import ShopSelect from '../common/ShopSelect'
import RefundAddressPane from './refundChildComponents/RefundAddressPane'
import RefundReasonPane from './refundChildComponents/RefundReasonPane'
import RefundOptionTypePane from './refundChildComponents/RefundOptionTypePane'
import RefundOptionPane from './refundChildComponents/RefundOptionPane'
export default {
  name: 'Refund',
  components: {
    BreadcrumbNav,
    // ShopSelect,
    RefundAddressPane,
    RefundReasonPane,
    RefundOptionTypePane,
    RefundOptionPane
  },
  data () {
    return {
      shopId: 0,
      activeTab: '退货地址'
    }
  },
  computed: {
    isBtnDisabled () {
      return false
    }
  },
  mounted () {
    this.$refs.address.getAddrList()
  },
  methods: {
    shopChange (shopId) {
      console.log('-------this.shopId:', shopId)
      this.shopId = shopId
    },
    tabClicked (tab, event) {
      console.log('tab:', tab.name)
      switch (tab.name) {
        case '退货地址':
          this.$refs.address.getAddrList()
          break
        case '退货原因':
          this.$refs.reason.getReasonList()
          break
        case '退货类型':
          this.$refs.refund.getOptionList()
          break
        case '退货方式':
          this.$refs.dy.getOptionList()
          break
        default:
          console.log('unknown tab:', tab.name)
      }
    }
  }
}
</script>

<style scoped>

</style>
