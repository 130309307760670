<template>
  <div>
    <el-button type="primary" size="mini" :disabled="isBtnDisabled" @click="showAddDialog">
      <slot name="title">添加退货地址</slot>
    </el-button>
    <el-table border stripe style="margin-top: 10px;" :data="list">
      <el-table-column type="index" label="序号" width="60px" align="center">
      </el-table-column>
      <el-table-column prop="name" label="姓名">
      </el-table-column>
      <el-table-column prop="mobile" label="手机号">
      </el-table-column>
      <el-table-column prop="isDefault" label="是否默认地址">
        <template slot-scope="{row}">
          <el-switch v-model="row.isDefault" active-text="是" inactive-text="不是" :active-value=1
                     :inactive-value=0 @change="changeDefaultAddr(row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="province" label="省">
      </el-table-column>
      <el-table-column prop="city" label="市">
      </el-table-column>
      <el-table-column prop="district" label="区">
      </el-table-column>
      <el-table-column prop="address" label="详细地址">
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="180px;">
        <template slot-scope="{row}">
          <el-row>
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="updateAddr(row)"
            >修改</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delAddr(row)"
            >删除</el-button
            >
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="form.id ? '修改地址' : '添加地址'"
      :visible.sync="dialogFormVisible"
    >
      <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
        <el-form-item label="姓名" label-width="100px" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" label-width="100px" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="默认地址" label-width="100px" prop="isDefault">
          <el-switch v-model="form.isDefault" active-text="是" inactive-text="不是" :active-value=1
                     :inactive-value=0>
          </el-switch>
        </el-form-item>
        <el-form-item label="选择地区" label-width="100px" prop="city">
          <el-cascader
            size="large"
            :options="options"
            v-model="selectedOptions"
            @change="handleChange"
            :style="{ width: '100%' }"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" label-width="100px" prop="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdateAddr">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pcaTextArr } from 'element-china-area-data'
import { UpdateAddrRequest, GetAddrListRequest, DelAddrRequest } from '../../../network/refund'
export default {
  name: 'RefundAddressPane',
  props: {
    isBtnDisabled: {
      type: Boolean,
      default: false
    },
    shopId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      options: pcaTextArr,
      selectedOptions: [],
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      form: {
        id: 0,
        name: '',
        mobile: '',
        province: '',
        city: '',
        district: '',
        address: '',
        isDefault: 0,
        shopId: 0,
        isSeller: 1
      },
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: '请输入收货人姓名', trigger: 'blur' }
        ],
        //
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        province: [
          { required: true, message: '请选择省', trigger: 'change' }
        ],
        city: [
          { required: true, message: '请选择市', trigger: 'blur' }
        ],
        district: [
          { required: true, message: '请选择区', trigger: 'change' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    shopId (newVal, oldVal) {
      this.shopId = newVal
      this.form.shopId = this.shopId
      console.log('---refund shopId:', this.shopId)
      this.getAddrList()
    }
  },
  methods: {
    showAddDialog () {
      this.dialogFormVisible = true
      this.form = {
        id: 0,
        name: '',
        mobile: '',
        province: '',
        city: '',
        district: '',
        address: '',
        isDefault: 0,
        isSeller: 1,
        shopId: this.shopId
      }
      this.selectedOptions = []
    },
    getAddrList () {
      GetAddrListRequest({ shopId: this.shopId }).then(res => {
        const result = res.data
        console.log('addr list:', result.data.list)
        if (res.status !== 200) {
          return this.alertMessage('获取地址列表失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
      })
    },
    handleChange (value) {
      if (value.length < 3) {
        return
      }
      this.form.province = value[0]
      this.form.city = value[1]
      this.form.district = value[2]
    },
    changeDefaultAddr (row) {
      this.form = { ...row }
      console.log('this.form:', this.form)
      UpdateAddrRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('更改默认地址失败', 'error')
        }
        this.alertMessage('更改默认地址成功', 'success')
        this.getAddrList()
      })
    },
    updateAddr (row) {
      this.dialogFormVisible = true
      this.form = { ...row }
      console.log('update addr:', this.form)
      this.selectedOptions.push(this.form.province, this.form.city, this.form.district)
    },
    delAddr (row) {
      console.log('del row:', row.id)
      this.$confirm('此操作将删除该地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DelAddrRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除地址失败', 'error')
          }
          this.alertMessage('删除地址成功', 'success')
          this.getAddrList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    addOrUpdateAddr () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        // if (this.form.shopId === 0) {
        //  return this.alertMessage('店铺id不能为空', 'error')
        // }
        this.dialogFormVisible = false
        console.log('this.form:', this.form)
        UpdateAddrRequest(this.form).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('添加地址失败', 'error')
          }
          if (this.form.id !== 0) {
            this.alertMessage('修改地址成功', 'success')
          } else {
            this.alertMessage('添加地址成功', 'success')
          }
          this.getAddrList()
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
