<template>
  <div>
    <el-button type="primary" size="mini" :disabled="isBtnDisabled" @click="showAddDialog">
      <slot name="title">添加退货类型</slot>
    </el-button>
    <el-table border stripe style="margin-top: 10px;" :data="list">
      <el-table-column type="index" label="序号" width="60px" align="center">
      </el-table-column>
      <el-table-column prop="imageUrl" label="图标" width="100px">
        <template slot-scope="{ row }">
          <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
        </template>
      </el-table-column>
      <el-table-column prop="subType" label="退货类型">
        <template slot-scope="scope">{{ scope.row.subType | subTypeLabel }}</template>
      </el-table-column>
      <el-table-column prop="desc" label="说明">
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="{row}">
          <el-switch v-model="row.status" active-text="可选" inactive-text="不可选" :active-value=1
                     :inactive-value=2 @change="changeStatus(row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="prop" label="操作" width="400px">
        <template slot-scope="{row, $index}">
          <el-row>
            <el-button
              type="warning"
              icon="el-icon-edit"
              size="mini"
              @click="updateOption(row)"
            >修改</el-button
            >
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              @click="delOption(row)"
            >删除</el-button
            >
            <el-button icon="el-icon-top"  type="success" size="mini" @click="upindex($index,row)">上移</el-button>
            <el-button icon="el-icon-bottom" type="success" size="mini"  @click="downindex($index,row)">下移</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="form.id ? '修改退货类型' : '添加退货类型'"
      :visible.sync="dialogFormVisible"
    >
      <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
        <el-form-item label="退货类型" label-width="100px" prop="subType">
          <el-select v-model="form.subType" style="width:100%" placeholder="请选择退货类型">
            <el-option
              v-for="item in subTypes"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="说明" label-width="100px" prop="desc">
          <el-input v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="图标" label-width="100px" prop="icon">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
            :headers="imgUploadHeaders"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addOrUpdateOption">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  DelOptionRequest,
  GetOptionListRequest,
  UpdateOptionStatusRequest,
  UpdateOptionRequest,
  AddOptionRequest,
  SortOptionRequest
} from '../../../network/refund'
export default {
  name: 'RefundOptionTypePane',
  props: {
    isBtnDisabled: {
      type: Boolean,
      default: false
    },
    shopId: {
      type: Number,
      default: 0
    }
  },
  filters: {
    subTypeLabel (value) {
      switch (value) {
        case 1:
          return '仅退款'
        case 2:
          return '退货退款'
        case 3:
          return '换货'
      }
    }
  },
  data () {
    return {
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      // 对话框显示与隐藏控制的属性
      dialogFormVisible: false,
      form: {
        icon: '',
        id: 0,
        refundType: 1,
        subType: '',
        shopId: 0,
        desc: ''
      },
      // 表单验证规则
      rules: {
        subType: [
          { required: true, message: '请输入退货类型', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请输入退货类型说明', trigger: 'blur' }
        ]
      },
      // 退回方式
      subTypes: [{
        label: '仅退款',
        value: 1
      }, {
        label: '退货退款',
        value: 2
      }, {
        label: '换货',
        value: 3
      }],
      uploadUrl: window.g.MEDIA_URL + '/upload',
      imgUploadHeaders: { // 上传图片控件的header
        Authorization: sessionStorage.getItem('token')
      },
      imageUrl: ''
    }
  },
  watch: {
    shopId (newVal, oldVal) {
      this.shopId = newVal
      this.form.shopId = this.shopId
      console.log('---refund reason shopId:', this.shopId)
      this.getOptionList()
    }
  },
  methods: {
    showAddDialog () {
      this.dialogFormVisible = true
      this.form = {
        icon: '',
        id: 0,
        refundType: 1,
        subType: '',
        shopId: this.shopId,
        desc: ''
      }
      this.imageUrl = ''
    },
    getOptionList () {
      GetOptionListRequest({ shopId: this.shopId, refundType: 1 }).then(res => {
        const result = res.data
        console.log('reason list:', result.data.list)
        if (res.status !== 200) {
          return this.alertMessage('获取退货类型列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.icon
        }
        this.total = result.data.total
      })
    },
    changeStatus (row) {
      console.log('change status:', row.status)
      UpdateOptionStatusRequest({ id: row.id, status: row.status }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('更改退货类型状态失败', 'error')
        }
        this.alertMessage('更改退货类型状态成功', 'success')
        this.getOptionList()
      })
    },
    updateOption (row) {
      this.dialogFormVisible = true
      this.form = { ...row }
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    delOption (row) {
      console.log('del row:', row.id)
      this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DelOptionRequest(row.id).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('删除退货类型失败', 'error')
          }
          this.alertMessage('删除退货类型成功', 'success')
          this.getOptionList()
        })
      }).catch(() => {
        this.alertMessage('已取消删除', 'info')
      })
    },
    updateOption2Srv () {
      UpdateOptionRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改退货类型失败', 'error')
        }
        this.alertMessage('修改退货类型成功', 'success')
        this.getOptionList()
      })
    },
    addOption2Srv () {
      AddOptionRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加退货类型失败', 'error')
        }
        this.alertMessage('添加退货类型成功', 'success')
        this.getOptionList()
      })
    },
    addOrUpdateOption () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        // if (this.form.shopId === 0) {
        //   return this.alertMessage('店铺id不能为空', 'error')
        // }
        this.dialogFormVisible = false
        console.log('this.form:', this.form)
        if (this.form.id) {
          this.updateOption2Srv()
        } else {
          this.addOption2Srv()
        }
      })
    },
    handleUploadSuccess (res, file) {
      console.log('handleUploadSuccess res:', res)
      this.form.icon = res.data.list[0].mediaId
      this.imageUrl = window.g.MEDIA_DOWNLOAD_URL + this.form.icon
    },
    upindex (index, row) {
      if (index > 0) {
        const curData = this.list[index]
        const upData = this.list[index - 1]
        const sortItem = []
        sortItem.push({ id: curData.id, sortNum: upData.sortNum })
        sortItem.push({ id: upData.id, sortNum: curData.sortNum })
        SortOptionRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('上移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = upData.sortNum
          upData.sortNum = curSortNum
          this.list.splice(index - 1, 1)
          this.list.splice(index, 0, upData)
        })
      } else {
        this.$message.warning('已经是第一条了！')
        return false
      }
    },
    downindex (index, row) {
      if (index + 1 === this.list.length) {
        this.$message.warning('已经是最后一条了！')
        return false
      } else {
        const downData = this.list[index + 1]
        const curData = this.list[index]
        const sortItem = []
        sortItem.push({ id: curData.id, sortNum: downData.sortNum })
        sortItem.push({ id: downData.id, sortNum: curData.sortNum })
        SortOptionRequest({ list: sortItem }).then(res => {
          if (res.status !== 200) {
            return this.alertMessage('下移失败', 'error')
          }
          const curSortNum = curData.sortNum
          curData.sortNum = downData.sortNum
          downData.sortNum = curSortNum
          this.list.splice(index + 1, 1)
          this.list.splice(index, 0, downData)
        })
      }
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
